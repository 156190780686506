import(/* webpackMode: "eager" */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/86/components/core/BasicImage/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/86/components/core/BasicLink/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/86/components/core/FullWrapper/style.module.sass");
;
import(/* webpackMode: "eager" */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/86/components/core/ScrollAnimation/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/86/components/features/main/Clients/ClientsItem/style.module.sass");
;
import(/* webpackMode: "eager" */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/86/components/features/main/NewProgress/style.module.sass");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/86/components/shared/sections/AboutDevelopment/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/86/components/shared/sections/NewProjectContainer/NewProject/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/86/components/shared/sections/NewProjectContainer/style.module.sass");
;
import(/* webpackMode: "eager" */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/86/components/features/main/Clients/style.module.sass");
;
import(/* webpackMode: "eager" */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/86/components/features/main/Title/style.module.sass");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/86/components/shared/sections/OfferButton/index.tsx");
